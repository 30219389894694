import * as z from "zod";
import {
  FormProvider,
  useForm,
  type UseFormReturn,
  type SubmitHandler,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const Schema = z.object({
  select1: z.string(),
  select2: z.string(),
  select3: z.string(),
  // itemCategory: z.string(),
  // itemRarity: z.string(),
  // sockets: z.string(),
  // class: z.string(),
  // auctionType: z.string(),
  // listPeriod: z.string(),
  // price: z.object({
  //   min: z.string(),
  //   max: z.string(),
  // }),
  // itemPower: z.object({
  //   min: z.string(),
  //   max: z.string(),
  // }),
  // levelRequirement: z.object({
  //   min: z.string(),
  //   max: z.string(),
  // }),
  search: z.string(),
});

type FormValues = z.infer<typeof Schema>;

export interface Option {
  value: string;
  label: string;
}

function Root({
  handleSubmit,
  onSubmit,
  children,
  ...rest
}: UseFormReturn<FormValues> & {
  onSubmit: SubmitHandler<FormValues>;
  children: React.ReactNode;
}): React.ReactNode {
  return (
    <FormProvider {...rest} handleSubmit={handleSubmit}>
      <form
        className="grid gap-2"
        onSubmit={(e) => {
          void handleSubmit(onSubmit)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}

function Header({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="grid gap-2 sm:grid-cols-2">{children}</div>;
}

function HeaderLeft({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="flex items-center gap-2">{children}</div>;
}

function HeaderRight({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="flex items-center gap-2 sm:justify-end">{children}</div>
  );
}

function FiltersContainer({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="grid gap-2 xl:grid-cols-2">{children}</div>;
}

function ClearFiltersBtn(): JSX.Element {
  return (
    <button
      className="flex h-[23px] w-[105px] items-center bg-background-muted bg-[image:url('/assets/buttons/button-background-gray.png')] px-2 font-diablo text-[10px] uppercase"
      type="button"
    >
      <span>Clear filter</span>
      <span className="ml-1 text-[#B51A1A]" role="img">
        x
      </span>
    </button>
  );
}

function ToggleFiltersBtn({
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button
      {...rest}
      className="h-[23px] w-[105px] bg-background-muted bg-[image:url('/assets/buttons/button-background-blue.png')] px-3 font-diablo text-[10px] uppercase"
      type="button"
    >
      {children}
    </button>
  );
}

function useSearchForm(): UseFormReturn<FormValues> {
  return useForm<FormValues>({
    resolver: zodResolver(Schema),
    defaultValues: {
      select1: "",
      select2: "",
      select3: "",
      search: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: false,
  });
}

export {
  Root,
  Header,
  HeaderLeft,
  HeaderRight,
  FiltersContainer,
  ClearFiltersBtn,
  ToggleFiltersBtn,
  useSearchForm,
};
