"use client";

import { LayoutDesktop } from "./layout-desktop";
import { LayoutMobile } from "./layout-mobile";

export default function Layout(): JSX.Element {
  return (
    <>
      <LayoutDesktop />
      <LayoutMobile />
    </>
  );
}
