function Root({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="h-80 bg-slate-800">{children}</div>;
}

function Title(): JSX.Element {
  return (
    <h3 className="mb-2 flex items-center justify-center text-center font-diablo text-sm uppercase">
      Affix Filters
    </h3>
  );
}

export { Root, Title };
