import { ChevronDown } from "lucide-react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectIcon,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "../../select";

function Root({ children }: React.PropsWithChildren): JSX.Element {
  return <div>{children}</div>;
}

function Title(): JSX.Element {
  return (
    <h3 className="mb-2 text-center font-diablo text-sm uppercase">
      General Filters
    </h3>
  );
}

function Filters({ children }: React.PropsWithChildren): JSX.Element {
  return <div>{children}</div>;
}

function ItemCategory({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  return (
    <Row
      field={
        <Select>
          <SelectTrigger className="py-0">
            <SelectValue className="px-4" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option, i) => {
                return (
                  <SelectItem
                    className="font-diablo"
                    key={i}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
      label="Item Category"
    />
  );
}

function ItemRarity({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  return (
    <Row
      field={
        <Select>
          <SelectTrigger className="py-0">
            <SelectValue className="px-4" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                return (
                  <SelectItem
                    className="font-diablo"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
      label="Item Rarity"
    />
  );
}

function Sockets({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  return (
    <Row
      field={
        <Select>
          <SelectTrigger className="py-0">
            <SelectValue className="px-4" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option, i) => {
                return (
                  <SelectItem
                    className="font-diablo"
                    key={i}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
      label="Sockets"
    />
  );
}

function Class({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  return (
    <Row
      field={
        <Select>
          <SelectTrigger className="py-0">
            <SelectValue className="px-4" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                return (
                  <SelectItem
                    className="font-diablo"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
      label="Class"
    />
  );
}

function AuctionType({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  return (
    <Row
      field={
        <Select>
          <SelectTrigger className="py-0">
            <SelectValue className="px-4" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                return (
                  <SelectItem
                    className="font-diablo"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
      label="Auction Type"
    />
  );
}

function ListPeriod({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  return (
    <Row
      field={
        <Select>
          <SelectTrigger className="py-0">
            <SelectValue className="px-4" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                return (
                  <SelectItem
                    className="font-diablo"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
      label="List Period"
    />
  );
}

function Price(): JSX.Element {
  return <Row field={<div>dsds</div>} label="Price" />;
}

function ItemPower(): JSX.Element {
  return <Row field={<div>dsds</div>} label="Item Power" />;
}

function LevelRequirement(): JSX.Element {
  return <Row field={<div>dsds</div>} label="Level Requirement" />;
}

// function RangeField({ label }: { label: string }): JSX.Element {
//   return (
//     <FilterField
//       field={
//         <div className="grid grid-cols-2 gap-2">
//           <label className="grid grid-cols-2 items-center">
//             <span className="pl-2 font-diablo text-xs">Min</span>
//             <input
//               className="h-full border-none bg-background-subtle px-1 font-diablo text-xs"
//               defaultValue={0}
//               type="number"
//             />
//           </label>
//           <label className="grid grid-cols-2 items-center">
//             <span className="font-diablo text-xs">Max</span>
//             <input
//               className="h-full border-none bg-background-subtle px-1 font-diablo text-xs"
//               defaultValue={0}
//               type="number"
//             />
//           </label>
//         </div>
//       }
//       label={label}
//     />
//   );
// }

function Row({
  label,
  field,
}: {
  label: string;
  field: React.ReactNode;
}): JSX.Element {
  return (
    <div className="grid h-8 grid-cols-2">
      <div className="flex items-center border border-background-subtle px-2 font-diablo text-base">
        {label}
      </div>
      <div className="grid border border-background-muted">{field}</div>
    </div>
  );
}

export {
  Root,
  Title,
  Filters,
  ItemCategory,
  ItemRarity,
  Sockets,
  Class,
  AuctionType,
  ListPeriod,
  Price,
  ItemPower,
  LevelRequirement,
};
