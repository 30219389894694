"use client";

import Image from "next/image";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import { useState, createContext, useContext, useEffect } from "react";
import {
  MoveLeft,
  MoveRight,
  ChevronDown,
  ArrowDownNarrowWide,
} from "lucide-react";
import { ScrollArea } from "../scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectIcon,
  SelectTrigger,
  SelectValue,
} from "../select";
import { MoneyBagIcon } from "../icons/money-bag";

interface ContextType {
  open: boolean;
}

const Context = createContext({} as ContextType);

function Sidebar(): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const value = {
    open,
  };

  useEffect(() => {
    setIsDesktop(window.matchMedia("(min-width: 1536px)").matches);
    window.matchMedia("(min-width: 1536px)").addEventListener("change", (e) => {
      setIsDesktop(e.matches);
    });
  }, []);

  useEffect(() => {
    if (isDesktop) {
      setOpen(true);
    }
  }, [isDesktop]);

  return (
    <Context.Provider value={value}>
      <aside className="grid">
        <div className="relative">
          <div
            className={twMerge(
              "absolute inset-0 z-10 flex w-80 flex-col rounded-md bg-background-emphasis shadow-lg shadow-background",
              open ? "w-80" : "w-20",
            )}
          >
            <div className="mb-4 mt-2 flex items-center px-2">
              <h2 className="flex items-center uppercase">
                <div>
                  <MoneyBagIcon className="mr-1 h-5 w-5" />
                </div>
                <div
                  className={twMerge(
                    "whitespace-nowrap font-diablo text-base",
                    !open && "w-0 overflow-hidden",
                  )}
                >
                  Trading activity
                </div>
              </h2>
              <div className="ml-auto flex">
                {!isDesktop ? (
                  <button
                    className={twMerge(!open && "hidden")}
                    onClick={() => {
                      setOpen(false);
                    }}
                    type="button"
                  >
                    <MoveLeft className="ml-auto h-5 w-5 font-medium" />
                  </button>
                ) : null}

                <button
                  className={twMerge(open && "hidden")}
                  onClick={() => {
                    setOpen(true);
                  }}
                  type="button"
                >
                  <MoveRight className="ml-auto h-5 w-5 font-medium" />
                </button>
              </div>
            </div>
            <Activities />
          </div>
        </div>
      </aside>
    </Context.Provider>
  );
}

function Activities() {
  const { open } = useContext(Context);
  return (
    <div className="flex flex-1 flex-col">
      <div
        className={twMerge(
          "mb-4 grid grid-cols-2 gap-2 px-2",
          !open && "hidden",
        )}
      >
        {/** ACTIVITY FILTERS */}
        <Select>
          <SelectTrigger>
            <SelectValue className="bg-primary" placeholder="Select" />
            <SelectIcon asChild>
              <ChevronDown className="h-5 w-5 opacity-80" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent className="text-xs">
            <SelectGroup>
              {Array.from({ length: 4 }).map((_, i) => {
                return (
                  <SelectItem
                    className="font-diablo text-base"
                    key={i}
                    value={String(i)}
                  >
                    Option {i}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Select>
          <SelectTrigger>
            <SelectValue placeholder="Select" />
            <SelectIcon asChild>
              <ArrowDownNarrowWide className="h-5 w-5 opacity-80" />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {Array.from({ length: 4 }).map((_, i) => {
                return (
                  <SelectItem
                    className="font-diablo text-base"
                    key={i}
                    value={String(i)}
                  >
                    Option {i}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {/** ACTIVITY LIST */}
      <div className="relative flex-1">
        <div className="absolute inset-0 flex h-full min-w-full">
          <ScrollArea className="top-0 max-w-full flex-1 overflow-hidden">
            <div className="items-center justify-center ">
              <div className="grid p-2">
                {Array.from({ length: 24 }).map((_, i) => {
                  return (
                    <Link
                      className={twMerge(
                        "flex h-16 items-center gap-2 font-diablo [&:not(:last-child)]:mb-2",
                      )}
                      href="/activities/2222"
                      key={i}
                      style={{
                        borderImage:
                          "url('/assets/misc/activity-border-legendary 1.png') 10 stretch",
                        borderImageWidth: "auto",
                      }}
                    >
                      <div
                        className={twMerge(
                          "relative",
                          !open && "flex w-16 items-center justify-center",
                        )}
                      >
                        <div className="relative h-[48px] w-[42px]">
                          <Image alt="" fill src="/ax.png" />
                        </div>
                        {!open && (
                          <div className="absolute right-2 top-0 mb-1 ml-auto flex h-4 w-4 items-center justify-center rounded-full bg-danger text-xs">
                            1
                          </div>
                        )}
                      </div>
                      <div
                        className={twMerge(
                          "flex-1 text-left text-sm text-primary",
                          !open ? "sr-only" : "",
                        )}
                      >
                        Ancestral Legendary Two-Handed Axe
                      </div>
                      <div className={twMerge("pr-2", !open ? "sr-only" : "")}>
                        <div className="mb-1 ml-auto  flex h-4 w-4 items-center justify-center rounded-full bg-danger  text-xs">
                          1
                        </div>
                        <div className="text-xs">2hr 21min Left</div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}

export { Sidebar };
