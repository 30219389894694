"use client";

import Link from "next/link";
import { useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { XCircle, ArrowDownNarrowWide } from "lucide-react";
import { Sidebar } from "../../components/sidebar";
import * as Form from "../../components/search-form";
import * as SearchInput from "../../components/search-form/components/search-input";
import * as GeneralFilters from "../../components/search-form/components/general-filters";
import * as AffixFilters from "../../components/search-form/components/affix-filters";
import { Select1 } from "../../components/search-form/components/select-1";
import { Select2 } from "../../components/search-form/components/select-2";
import { Select3 } from "../../components/search-form/components/select-3";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectIcon,
  SelectValue,
} from "../../components/select";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/collapsible";
import { ScrollArea } from "../../components/scroll-area";
import * as Layout from "../../components/layout";

function noop(): string {
  return "";
}

function LayoutDesktop(): JSX.Element {
  const pathname = usePathname();
  const router = useRouter();
  const form = Form.useSearchForm();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="hidden xl:block">
      <Layout.Header />
      <Layout.Body>
        <Layout.BodyLeftAd>
          <div className="flex h-[600px] items-center justify-center rounded-md bg-[#fff] font-medium uppercase text-background">
            Ad
          </div>
        </Layout.BodyLeftAd>
        <Layout.BodyContent>
          <Layout.BodyContentLeftSidebar>
            <Sidebar />
          </Layout.BodyContentLeftSidebar>

          <Layout.BodyContentMiddle>
            <div className="grid grid-rows-[auto_1fr] gap-2 overflow-hidden rounded-md bg-background-emphasis p-2">
              <Form.Root
                {...form}
                onSubmit={() => {
                  noop();
                }}
              >
                <Collapsible onOpenChange={setOpen} open={open}>
                  <Form.Header>
                    <Form.HeaderLeft>
                      <Select1 options={[]} />
                      <Select2 options={[]} />
                      <Select3 options={[]} />
                    </Form.HeaderLeft>
                    <Form.HeaderRight>
                      <Form.ClearFiltersBtn />
                      <CollapsibleTrigger asChild>
                        <Form.ToggleFiltersBtn>
                          {open ? "Hide" : "Show"} Filters
                        </Form.ToggleFiltersBtn>
                      </CollapsibleTrigger>
                    </Form.HeaderRight>
                  </Form.Header>

                  <SearchInput.Root>
                    <SearchInput.Input />
                    <SearchInput.Button />
                  </SearchInput.Root>

                  <CollapsibleContent>
                    <Form.FiltersContainer>
                      <GeneralFilters.Root>
                        <GeneralFilters.Filters>
                          <GeneralFilters.ItemCategory options={[]} />
                          <GeneralFilters.ItemRarity options={[]} />
                          <GeneralFilters.Sockets options={[]} />
                          <GeneralFilters.Class options={[]} />
                          <GeneralFilters.AuctionType options={[]} />
                          <GeneralFilters.ListPeriod options={[]} />
                          <GeneralFilters.Price />
                        </GeneralFilters.Filters>
                      </GeneralFilters.Root>
                      <AffixFilters.Root>AFFIX FILTERS</AffixFilters.Root>
                    </Form.FiltersContainer>
                  </CollapsibleContent>
                </Collapsible>
              </Form.Root>

              <div className="flex flex-col gap-4">
                <div className="relative flex h-10 items-center justify-center justify-items-center bg-background-subtle">
                  <h3 className="font-diablo">
                    Showing 100 Results(100000 Matched)
                  </h3>
                  <div className="absolute bottom-0 right-0 top-0 w-40">
                    <Select>
                      <SelectTrigger className="h-10 bg-background-muted py-0 font-diablo text-base">
                        <SelectValue className="px-4" />
                        <SelectIcon asChild>
                          <ArrowDownNarrowWide className="h-5 w-5 opacity-50" />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="newest">Newest</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="relative flex-1">
                  <div className="absolute top-0 flex h-full min-w-full">
                    <ScrollArea className="top-0 max-w-full flex-1  overflow-hidden">
                      <div className="grid grid-cols-3  justify-items-center gap-4">
                        {Array.from({ length: 16 }).map(() => {
                          return (
                            <Link
                              className="-m-1 flex min-h-[380px] w-[251px] items-center justify-center rounded-md"
                              href="/activities/222"
                              key={Math.random()}
                              style={{
                                borderImage:
                                  "url('/assets/card/legendary-border.png') 188 stretch",
                                borderImageWidth: "auto",
                              }}
                            />
                          );
                        })}
                      </div>
                    </ScrollArea>
                  </div>
                </div>
              </div>
            </div>
          </Layout.BodyContentMiddle>
          <Layout.BodyContentRightSidebar>
            <div className="grid bg-background-emphasis">
              {pathname === "/" ? (
                <div className="flex h-[600px] w-[160px] items-center justify-center bg-[white] font-medium text-background">
                  AD
                </div>
              ) : (
                <div className="rounded-md bg-background-emphasis p-2">
                  <h3 className="mb-4 flex items-center justify-between font-diablo text-xs uppercase">
                    Auction Information
                    <button
                      onClick={() => {
                        router.replace("/");
                      }}
                      type="button"
                    >
                      <XCircle className="h-6 w-6 stroke-1" />
                    </button>
                  </h3>

                  <div
                    className="-m-2 flex min-h-[360px] items-center justify-center rounded-md"
                    style={{
                      borderImage:
                        "url('/assets/card/legendary-border.png') 188 stretch",
                      borderImageWidth: "auto",
                    }}
                  />
                </div>
              )}
            </div>
          </Layout.BodyContentRightSidebar>
        </Layout.BodyContent>
        <Layout.BodyRightAd>
          <div className="flex h-[600px] items-center justify-center rounded-md bg-[#fff] font-medium uppercase text-background">
            Ad
          </div>
        </Layout.BodyRightAd>
      </Layout.Body>
    </div>
  );
}

export { LayoutDesktop };
