"use client";

import Image from "next/image";
import { twMerge } from "tailwind-merge";
import { ArrowDownNarrowWide } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectIcon,
  SelectValue,
} from "../../components/select";
import { ScrollArea } from "../../components/scroll-area";
import * as LayoutTabs from "../../components/tabs-layout";
import * as SearchTabs from "../../components/search-tabs";
import * as Form from "../../components/search-form";
import * as SearchInput from "../../components/search-form/components/search-input";
import * as GeneralFilters from "../../components/search-form/components/general-filters";
import * as AffixFilters from "../../components/search-form/components/affix-filters";
import { Select1 } from "../../components/search-form/components/select-1";
import { Select2 } from "../../components/search-form/components/select-2";
import { Select3 } from "../../components/search-form/components/select-3";

function noop(): string {
  return "";
}

function LayoutMobile(): JSX.Element {
  const form = Form.useSearchForm();
  return (
    <LayoutTabs.Root defaultValue="search">
      <LayoutTabs.Header />
      <LayoutTabs.TabsList />
      <LayoutTabs.TabsContentContainer>
        <LayoutTabs.TabsContent value="trade">
          <div className="grid grid-rows-[auto_1fr] gap-4">
            <Select>
              <SelectTrigger className="bg-background-muted py-0 font-diablo">
                <SelectValue className="px-4" placeholder="Select" />
                <SelectIcon asChild>
                  <ArrowDownNarrowWide className="h-5 w-5 opacity-50" />
                </SelectIcon>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem className="font-diablo" value="active_listing">
                    Active Listing
                  </SelectItem>
                  <SelectItem className="font-diablo" value="active_bids">
                    Active Bids
                  </SelectItem>
                  <SelectItem className="font-diablo" value="closed_bids">
                    Closed Bids
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="relative grid">
              <div className="absolute inset-0 flex min-w-full">
                <ScrollArea className="top-0 max-w-full flex-1  overflow-hidden">
                  <div className="h-[20rem] items-center justify-center ">
                    <div className="grid gap-4">
                      {Array.from({ length: 10 }).map((_) => {
                        return (
                          <button
                            className={twMerge(
                              "mx-[-1px] mb-[-14px] flex h-16 items-center gap-2 pb-1 font-diablo",
                            )}
                            key={Math.random()}
                            style={{
                              borderImage:
                                "url('/assets/misc/activity-border-legendary 1.png') 11 stretch",
                              borderImageWidth: "auto",
                            }}
                            type="button"
                          >
                            <div className="relative  h-[48px] w-[42px]">
                              <Image alt="" fill src="/ax.png" />
                            </div>

                            <div className="flex-1 text-left text-primary">
                              Ancestral Legendary Two-Handed Axe
                            </div>
                            <div className="pr-4">
                              <div className="mb-1 ml-auto  flex h-4 w-4 items-center justify-center rounded-full bg-danger  text-xs">
                                1
                              </div>
                              <div>2hr 21min Left</div>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </ScrollArea>
              </div>
            </div>
          </div>
        </LayoutTabs.TabsContent>

        <LayoutTabs.TabsContent value="search">
          <SearchTabs.Root>
            <SearchTabs.Content value="search">
              <Form.Root
                {...form}
                onSubmit={() => {
                  noop();
                }}
              >
                <Form.Header>
                  <Form.HeaderLeft>
                    <Select1 options={[]} />
                    <Select2 options={[]} />
                    <Select3 options={[]} />
                  </Form.HeaderLeft>
                  <Form.HeaderRight>
                    <Form.ClearFiltersBtn />
                  </Form.HeaderRight>
                </Form.Header>
                <SearchInput.Root>
                  <SearchInput.Input />
                  <SearchInput.Button />
                </SearchInput.Root>
                <Form.FiltersContainer>
                  <GeneralFilters.Root>
                    <GeneralFilters.Filters>
                      <GeneralFilters.ItemCategory options={[]} />
                      <GeneralFilters.ItemRarity options={[]} />
                      <GeneralFilters.Sockets options={[]} />
                      <GeneralFilters.Class options={[]} />
                      <GeneralFilters.AuctionType options={[]} />
                      <GeneralFilters.ListPeriod options={[]} />
                      <GeneralFilters.Price />
                    </GeneralFilters.Filters>
                  </GeneralFilters.Root>
                  <AffixFilters.Root>AFFIX FILTERS</AffixFilters.Root>
                </Form.FiltersContainer>
              </Form.Root>
            </SearchTabs.Content>
            <SearchTabs.Content value="result">
              <p className="text-3xl">result</p>
            </SearchTabs.Content>
          </SearchTabs.Root>
        </LayoutTabs.TabsContent>

        <LayoutTabs.TabsContent value="user">
          <p className="text-3xl">Infos do usuario autenticado</p>
        </LayoutTabs.TabsContent>
      </LayoutTabs.TabsContentContainer>
    </LayoutTabs.Root>
  );
}

export { LayoutMobile };
