import { Controller } from "react-hook-form";

function Root({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="flex h-[30px] gap-2">{children}</div>;
}

function Input(): JSX.Element {
  return (
    <Controller
      name="search"
      render={({ field }) => {
        return (
          <input
            className=" h-[30px] flex-1 border border-background-muted bg-transparent px-2 font-diablo text-sm"
            placeholder="Search..."
            type="text"
            {...field}
          />
        );
      }}
    />
  );
}

function Button(): JSX.Element {
  return (
    <button
      className="h-[30px] w-[144px] bg-background-muted bg-[image:url('/assets/buttons/button-background-red.png')] font-diablo text-xs uppercase"
      type="submit"
    >
      Search
    </button>
  );
}
export { Root, Input, Button };
