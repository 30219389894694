import { Tabs, TabsList, TabsTrigger, TabsContent } from "../tabs";
import { ScrollArea } from "../scroll-area";
function Root({ children }: React.ComponentProps<typeof Tabs>): JSX.Element {
  return (
    <Tabs className="relative grid grid-rows-[auto_1fr]" defaultValue="search">
      <TabsList className="grid grid-cols-2">
        <TabsTrigger value="search">Search</TabsTrigger>
        <TabsTrigger value="result">Results</TabsTrigger>
      </TabsList>
      <div className="grid">{children}</div>
    </Tabs>
  );
}

function Content({
  children,
  value,
}: React.ComponentProps<typeof TabsContent>): JSX.Element {
  return (
    <TabsContent
      className="data-[state=active]:grid data-[state=inactive]:hidden"
      value={value}
    >
      <div className="relative flex-1">
        <div className="absolute top-0 flex h-full min-w-full">
          <ScrollArea className="top-0 max-w-full flex-1  overflow-hidden">
            <div className="">{children}</div>
          </ScrollArea>
        </div>
      </div>
    </TabsContent>
  );
}

export { Root, Content };
